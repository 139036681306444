import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
} from "@mui/material";

const LoadListData = () => {
  const { loadlistId, userId } = useParams(); // Get the loadlist ID from the URL params
  const [loadlistData, setLoadlistData] = useState([]); // State to store the fetched data
  const [userData, setUserData] = useState([]); // State to store the fetched data
  const [loadDate, setLoadDate] = useState(""); // State to store the load_date
  const [startingMileage, setStartingMileage] = useState(
    localStorage.getItem("startingMileage") || 0
  ); // Load from localStorage
  const [endingMileage, setEndingMileage] = useState(
    localStorage.getItem("endingMileage") || 0
  ); // Load from localStorage
  const [totalAmount, setTotalAmount] = useState(0); // State for Total Amount
  const [loadlistDetails, setLoadlistDetails] = useState([]); // State to store the full loadlist_detail objects
  const [sortedAddresses, setSortedAddresses] = useState([]);
  const [storedSortedIds, setSortedIds] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("");
  const navigate = useNavigate(); // Hook to navigate to the detailed page
  const MAX_LOCATIONS = 10; // Maximum number of origins/destinations per batch (based on 100 elements limit)

  useEffect(() => {
    fetchLoadlistData();
    fetchExistingDetails(); // Fetch full loadlist_detail objects
    fetchUserData();
  }, [loadlistId]);

  const fetchLoadlistData = async () => {
    try {
      const response = await fetch(`/api/loadlistdata/${loadlistId}/${userId}`); // API call to get data by loadlist ID
      const data = await response.json();

      const total = data.reduce((sum, row) => sum + (row.load_amount || 0), 0);
      setTotalAmount(total); // Set the total load amount
      const loadlistResponse = await fetch(`/api/loadlist/${loadlistId}`); // Fetch the loadlist details
      const loadlist = await loadlistResponse.json();
      setLoadDate(loadlist.load_date); // Set the load_date in the state

      // Check if a saved sort exists in localStorage
      const savedSort = localStorage.getItem(
        "sortedLoadlistData_" + loadlistId + "_" + userId
      );
      if (savedSort) {
        const sortedIds = JSON.parse(savedSort);
        setSortedIds(sortedIds);
        // Sort data based on the saved IDs
        const sortedData = [
          ...sortedIds
            .map((id) => data.find((item) => item.id === id)) // Find items matching saved IDs
            .filter(Boolean), // Remove null/undefined entries for non-matching IDs
          ...data.filter((item) => !sortedIds.includes(item.id)), // Append items not in saved sort
        ];

        setLoadlistData(sortedData); // Update state with the sorted data
      } else {
        setLoadlistData(data); // No saved sort, use fetched data as-is
      }
    } catch (error) {
      console.error("Error fetching loadlist data:", error);
    }
  };

  const fetchExistingDetails = async () => {
    try {
      const response = await fetch(
        `/api/existing-loadlist-details/${loadlistId}`
      ); // API call to fetch full loadlist_detail objects
      const details = await response.json();
      setLoadlistDetails(details); // Store the full details in state
    } catch (error) {
      console.error("Error fetching existing loadlist details:", error);
    }
  };

  const fetchUserData = async () => {
    try {
      console.log("userid: " + userId);
      const response = await fetch(`/api/users/${userId}`); // API call to fetch full loadlist_detail objects
      const user = await response.json();
      console.log(user);
      setUserData(user); // Store the full details in state
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const rowExistsInDetails = (rowId) => {
    return loadlistDetails.some((detail) => detail.loadlist_data_id === rowId);
  };

  const handleCompleteLoading = async () => {
    if (startingMileage === "" || parseInt(startingMileage) === 0) {
      alert("Starting Mileage must not be 0.");
      return;
    }

    if (endingMileage === "" || parseInt(endingMileage) === 0) {
      alert("Ending Mileage must not be 0.");
      return;
    }

    if (parseInt(startingMileage) >= parseInt(endingMileage)) {
      alert("Starting Mileage must be less than Ending Mileage.");
      return;
    }

    const allRowsHaveDetails = loadlistData.every((row) =>
      rowExistsInDetails(row.id)
    );
    if (!allRowsHaveDetails) {
      alert("All load list rows must have completed details.");
      return;
    }

    const totalLoadedAmount = loadlistDetails.reduce(
      (sum, detail) => sum + (detail.amount_loaded || 0),
      0
    );
    if (totalLoadedAmount !== totalAmount) {
      alert(
        `Amount Loaded (${totalLoadedAmount}) does not match the Total Amount (${totalAmount})`
      );
      return;
    }

    const summaryData = {
      loadlist_id: loadlistId,
      startingMileage: parseInt(startingMileage),
      endingMileage: parseInt(endingMileage),
      totalAmount: totalAmount,
      userId: userId,
    };

    try {
      const response = await fetch("/api/save-loadlist-summary", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(summaryData),
      });

      const result = await response.json();
      if (result.success) {
        localStorage.setItem("startingMileage", 0); // Save to localStorage
        localStorage.setItem("endingMileage", 0); // Save to localStorage
        localStorage.removeItem("sortedLoadlistData_" + loadlistId + "_" + userId)
        alert("Loading summary saved successfully.");
        navigate("/loadselect");
      } else {
        console.error("Failed to save summary:", result.error);
        alert("Failed to save summary.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while saving the summary.");
    }
  };

  const handleStartingMileageChange = (e) => {
    const value = e.target.value;
    setStartingMileage(value);
    localStorage.setItem("startingMileage", value); // Save to localStorage
  };

  const handleEndingMileageChange = (e) => {
    const value = e.target.value;
    setEndingMileage(value);
    localStorage.setItem("endingMileage", value); // Save to localStorage
  };

  const handleRowClick = (rowData) => {
    var existingData = loadlistDetails.find(
      (detail) => detail.loadlist_data_id === rowData.id
    );
    navigate(`/loadlistdetail/${loadlistId}/${userId}/${rowData.id}`, {
      state: { rowData, existingData },
    });
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("T")[0].split("-"); // Split by 'T' first to remove the time part
    return `${month}/${day}/${year}`; // Return in MM/DD/YYYY format
  };

  const getRowColor = (row) => {
    if (rowExistsInDetails(row.id)) {
      var rowDetailData = loadlistDetails.find(
        (detail) => detail.loadlist_data_id === row.id
      );
      if (rowDetailData) {
        if (rowDetailData.amount_loaded != row.load_amount) {
          console.log(rowDetailData);
          return "yellow";
        }
      }
      return "lightgreen";
    } else {
      return "transparent";
    }
  };

  // Fetch a single batch of distances
  const fetchDistanceMatrixBatch = async (originsBatch, destinationsBatch) => {
    const originsString = originsBatch.join("|");
    const destinationsString = destinationsBatch.join("|");

    try {
      const response = await fetch(
        `/api/distance-matrix?origins=${encodeURIComponent(
          originsString
        )}&destinations=${encodeURIComponent(destinationsString)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`API error: ${response.status}`);
      }

      const data = await response.json();

      if (data.status !== "OK") {
        throw new Error(`Google Maps API error: ${data.status}`);
      }

      // Parse the matrix as a 2D array of distances
      return data.rows.map((row) =>
        row.elements.map((el) => el.distance?.value || Infinity)
      );
    } catch (error) {
      console.error("Error fetching distance matrix batch:", error);
      throw error;
    }
  };

  // Main function to handle batching
  const getDistanceMatrix = async (locations) => {
    const n = locations.length;
    const fullMatrix = Array.from({ length: n }, () => Array(n).fill(Infinity));

    // Split into batches for origins
    for (let i = 0; i < n; i += MAX_LOCATIONS) {
      const originsBatch = locations.slice(i, i + MAX_LOCATIONS);

      // Split into batches for destinations
      for (let j = 0; j < n; j += MAX_LOCATIONS) {
        const destinationsBatch = locations.slice(j, j + MAX_LOCATIONS);

        // Fetch each batch
        const batchMatrix = await fetchDistanceMatrixBatch(
          originsBatch,
          destinationsBatch
        );

        // Populate the full matrix
        for (let rowIndex = 0; rowIndex < batchMatrix.length; rowIndex++) {
          const globalRowIndex = i + rowIndex;

          for (
            let colIndex = 0;
            colIndex < batchMatrix[rowIndex].length;
            colIndex++
          ) {
            const globalColIndex = j + colIndex;

            fullMatrix[globalRowIndex][globalColIndex] =
              batchMatrix[rowIndex][colIndex];
          }
        }
      }
    }

    return fullMatrix;
  };

  const solveTSP = (distanceMatrix) => {
    const n = distanceMatrix.length;
    const visited = Array(n).fill(false);
    visited[0] = true; // Mark the origin as visited

    const route = [0]; // Start from the origin
    let current = 0;

    for (let i = 0; i < n - 1; i++) {
      let next = -1;
      let shortestDistance = Infinity;

      for (let j = 0; j < n; j++) {
        if (!visited[j] && distanceMatrix[current][j] < shortestDistance) {
          next = j;
          shortestDistance = distanceMatrix[current][j];
        }
      }

      visited[next] = true; // Mark the next location as visited
      route.push(next); // Add to the route
      current = next; // Update the current location
    }

    return route; // Return the optimized visiting order
  };

  // Handle button click
  const handleSortAddresses = async () => {
    // Check if data has already been sorted
    if (storedSortedIds.length > 0) {
      const confirmResort = window.confirm(
        "The load list is already sorted. Are you sure you want to re-sort?"
      );
      if (!confirmResort) {
        return; // Exit if the user cancels the resort action
      }
    }
  
    var originAddress = await getCurrentLocation();
    setCurrentLocation(originAddress);
  
    if (!originAddress.trim()) {
      alert("Please enter a starting address.");
      return;
    }
  
    try {
      // Separate valid and invalid rows
      const validRows = loadlistData.filter(
        (atm) =>
          atm.address &&
          atm.city &&
          atm.state &&
          atm.zip &&
          getRowColor(atm) == "transparent"
      );
      const invalidRows = loadlistData.filter(
        (atm) => !atm.address || !atm.city || !atm.state || !atm.zip
      );
      const completeRows = loadlistData.filter(
        (atm) => getRowColor(atm) != "transparent"
      );
  
      // Group rows by address
      const addressMap = new Map();
      validRows.forEach((row) => {
        const fullAddress = `${row.address}, ${row.city}, ${row.state}, ${row.zip}`;
        if (!addressMap.has(fullAddress)) {
          addressMap.set(fullAddress, []);
        }
        addressMap.get(fullAddress).push(row);
      });
  
      // Extract unique addresses
      const uniqueAddresses = Array.from(addressMap.keys());
  
      console.log("Unique Addresses:", uniqueAddresses);
  
      const allLocations = [originAddress, ...uniqueAddresses];
  
      // Fetch distances from the origin to all unique addresses
      const distances = await getDistanceMatrix(allLocations);
      console.log("Distances:", distances);
  
      // Solve TSP to get the optimal order
      const route = solveTSP(distances);
      console.log("Route:", route);
  
      // Map the sorted route to grouped rows
      const sortedValidRows = route.slice(1).flatMap((index) => {
        const address = uniqueAddresses[index - 1]; // Exclude origin
        return addressMap.get(address); // Get all rows for this address
      });
  
      // Combine sorted valid rows with invalid rows
      const sortedLoadlistData = [
        ...sortedValidRows,
        ...completeRows,
        ...invalidRows,
      ];
      setLoadlistData(sortedLoadlistData);
  
      // Optional: Set sorted addresses for display/debugging
      const sortedAddresses = sortedValidRows.map(
        (atm) => `${atm.address}, ${atm.city}, ${atm.state}, ${atm.zip}`
      );
      setSortedAddresses(sortedAddresses);
  
      // Store the sort locally so it can be retrieved
      var localStorageSortedId =
        "sortedLoadlistData_" + loadlistId + "_" + userId;
      var sortedIdList = sortedLoadlistData.map((item) => {
        return item.id;
      });
      localStorage.setItem(localStorageSortedId, JSON.stringify(sortedIdList));
      setSortedIds(sortedIdList);
  
      console.log("Sorted Loadlist Data:", sortedLoadlistData);
    } catch (err) {
      console.error("Error during address sorting:", err);
      alert("An error occurred while sorting addresses.");
    }
  };
  

  const getCurrentLocation = async () => {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve(`${latitude},${longitude}`);
          },
          (err) => {
            alert("Unable to retrieve location");
            reject("Unable to retrieve location");
          }
        );
      });
    } else {
      console.log("Setting default location for testing");
      return "6232 Regiment Dr., Jacksonville, FL, 32277";
    }
  };

  const openGoogleMapsRoute = () => {
    if (!currentLocation.trim() || sortedAddresses.length === 0) {
      alert("Please sort addresses before generating a route.");
      return;
    }

    // Group rows by address
    const addressMap = new Map();
    sortedAddresses.forEach((address) => {
      if (!addressMap.has(address)) {
        addressMap.set(address, []);
      }
      addressMap.get(address).push(address);
    });

    // Extract unique addresses
    const uniqueAddresses = Array.from(addressMap.keys());

    const start = encodeURIComponent(currentLocation);
    const waypoints = uniqueAddresses
      .slice(0, -1) // All but the last address are waypoints
      .map(encodeURIComponent)
      .join("|");
    const end = encodeURIComponent(uniqueAddresses[uniqueAddresses.length - 1]);

    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${start}&destination=${end}&waypoints=${waypoints}`;

    // Open Google Maps in a new tab
    window.open(googleMapsUrl, "_blank");
  };

  return (
    <Container maxWidth="md" sx={styles.container}>
      <Typography variant="h4" gutterBottom>
        Load List for: {formatDate(loadDate)}
      </Typography>

      <Box sx={styles.fieldContainer}>
        <TextField
          label="Total Amount"
          value={totalAmount}
          InputProps={{ readOnly: true }}
          variant="outlined"
          fullWidth
          sx={styles.readOnlyField}
        />
      </Box>

      <Box sx={styles.fieldContainer}>
        <TextField
          label="Starting Mileage"
          value={startingMileage}
          onChange={handleStartingMileageChange}
          variant="outlined"
          fullWidth
          sx={styles.inputField}
        />
      </Box>

      <Button
        onClick={handleSortAddresses}
        variant="contained"
        color="secondary"
        sx={{
          backgroundColor: "#4caf50", // Green for emphasis
          color: "white",
          fontWeight: "bold",
          padding: "10px 20px",
          fontSize: "1rem",
          margin: "10px",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "#45a049", // Slightly darker green on hover
          },
        }}
      >
        Sort Fastest Route
      </Button>

      {storedSortedIds.length > 0 && (
        <Button
          onClick={openGoogleMapsRoute}
          variant="contained"
          color="secondary"
          sx={{
            backgroundColor: "#2196f3", // Blue for differentiation
            color: "white",
            fontWeight: "bold",
            padding: "10px 20px",
            fontSize: "1rem",
            margin: "10px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#1976d2", // Slightly darker blue on hover
            },
          }}
        >
          See Route
        </Button>
      )}

      <Table sx={{ marginBottom: "20px", cursor: "pointer" }}>
        <TableHead>
          <TableRow>
            <TableCell>Location Name</TableCell>
            <TableCell>Balance</TableCell>
            <TableCell>Load Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadlistData.map((row, index) => (
            <TableRow
              key={index}
              onClick={() => handleRowClick(row)}
              sx={{
                cursor: "pointer",
                backgroundColor: getRowColor(row),
              }}
            >
              <TableCell>{row.location_name}</TableCell>
              <TableCell>{row.balance}</TableCell>
              <TableCell>{row.load_amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Box sx={styles.fieldContainer}>
        <TextField
          label="Ending Mileage"
          value={endingMileage}
          onChange={handleEndingMileageChange}
          variant="outlined"
          fullWidth
          sx={styles.inputField}
        />
      </Box>

      <Button
        onClick={handleCompleteLoading}
        variant="contained"
        color="primary"
        fullWidth
        sx={styles.saveButton}
      >
        Complete Loading
      </Button>
    </Container>
  );
};

// Styles for MUI's `sx` prop
const styles = {
  container: {
    padding: "20px",
    fontFamily: "Arial",
    textAlign: "center",
  },
  fieldContainer: {
    marginBottom: "20px",
  },
  readOnlyField: {
    backgroundColor: "#cce6ff",
    fontSize: "1.2rem", // Larger text for readability
  },
  inputField: {
    backgroundColor: "#f8d7da",
    fontSize: "1.2rem", // Larger input text for readability
  },
  saveButton: {
    padding: "15px",
    backgroundColor: "#007bff",
    fontWeight: "bold",
    fontSize: "1.2rem", // Larger button text
  },
};

export default LoadListData;
