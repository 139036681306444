import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const SummaryPage = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [summaryData, setSummaryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleGetSummary = async () => {
    if (!fromDate || !toDate) {
      alert("Please select both From and To dates.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `/api/summaries?from=${dayjs(fromDate).format("YYYY-MM-DD")}&to=${dayjs(
          toDate
        ).format("YYYY-MM-DD")}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch summary data.");
      }
      const data = await response.json();
      setSummaryData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Calculate total amount
  const totalAmount = summaryData.reduce(
    (sum, row) => sum + (row.total_amount || 0),
    0
  );

  // Get row color based on status
  const getRowColor = (status) => {
    switch (status.toLowerCase()) {
      case "complete":
        return "lightgreen";
      case "in progress":
        return "yellow";
      case "not started":
        return "lightcoral";
      default:
        return "white";
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Loadlist Summary
      </Typography>

      <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={(newValue) => setFromDate(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
          <DatePicker
            label="To Date"
            value={toDate}
            onChange={(newValue) => setToDate(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Box>

      <Typography variant="h6" sx={{ mb: 2 }}>
        Total Amount: ${totalAmount.toFixed(2)}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={handleGetSummary}
        sx={{ mb: 3 }}
      >
        Get Summary
      </Button>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        summaryData.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Loadlist ID</TableCell>
                <TableCell>Load Date</TableCell>
                <TableCell>Summary ID</TableCell>
                <TableCell>Miles Driven</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {summaryData.map((row) => (
                <TableRow
                  key={row.summary_id}
                  sx={{
                    backgroundColor: getRowColor(row.status),
                  }}
                >
                  <TableCell>{row.loadlist_id}</TableCell>
                  <TableCell>
                    {row.load_date
                      ? dayjs(row.load_date).format("MM/DD/YYYY")
                      : "N/A"}
                  </TableCell>
                  <TableCell>{row.summary_id}</TableCell>
                  <TableCell>{row.miles_driven}</TableCell>
                  <TableCell>{row.total_amount?.toFixed(2)}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {row.updated_at
                      ? dayjs(row.updated_at).format("MM/DD/YYYY hh:mm A")
                      : "N/A"}
                  </TableCell>
                  <TableCell>{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )
      )}
    </Container>
  );
};

export default SummaryPage;
