import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Box, Container } from '@mui/material';

const HomePage = () => {
  const navigate = useNavigate(); // Hook for navigation

  // Handlers for button clicks
  const handleUploadClick = () => {
    navigate('/upload'); // Navigate to the Upload LoadList page
  };

  const handleSummaryClick = () => {
    navigate('/summary'); // Navigate to the Summary Reports page
  };

  const handleLoadingClick = () => {
    navigate('/loadselect'); // Navigate to the Loading page
  };

  return (
    <Container maxWidth="sm" sx={styles.container}>
      <Typography variant="h4" align="center" gutterBottom>
        Welcome to ATM Load Management
      </Typography>

      <Box sx={styles.buttonContainer}>
        {/* Unique Summary Reports Button */}
        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={handleSummaryClick}
          sx={styles.uniqueButton}
        >
          View Summary Reports
        </Button>
        
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={handleUploadClick}
          sx={styles.button}
        >
          Upload Load List
        </Button>

        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={handleLoadingClick}
          sx={styles.button}
        >
          Start Loading
        </Button>
      </Box>
    </Container>
  );
};

// Styles for the component using MUI's `sx` prop
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: '20px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    marginTop: '30px',
  },
  button: {
    padding: '15px',
    fontSize: '16px',
  },
  uniqueButton: {
    padding: '15px',
    fontSize: '18px', // Slightly larger font size
    backgroundColor: '#4caf50', // Distinct green color
    color: '#fff',
    fontWeight: 'bold',
    border: '2px solid #388e3c', // Add a border for emphasis
    textTransform: 'uppercase', // All caps for distinction
    transition: 'transform 0.2s, background-color 0.2s', // Add hover effects
    '&:hover': {
      backgroundColor: '#388e3c', // Darker green on hover
      transform: 'scale(1.05)', // Slight zoom effect on hover
    },
  },
};

export default HomePage;
